import errorsHandler from '@/utils/errorsHandler';
const adminPanelQuery = {
  methods: {
    updateQuery() {
      if (
        this.$route.query.q !== this.searchElements ||
        this.$route.query.on_page != this.filterPage ||
        this.$route.query.per_page != this.selectedPerPage ||
        this.$route.query.sort != this.sortString ||
        this.$route.query.agency_id != this.selectedAgency?.id ||
        this.$route.query.genre_id != (this.selectedGenre?.id || this.filters?.genre?.id) ||
        this.$route.query.year != this.filterDate?.id ||
        this.$route.query.channel_id != (this.filterChannel?.id || this.filters?.channel?.id) ||
        this.$route.query.saleshouse_id != this.filterSaleshouse?.id ||
        this.$route.query.role_id != this.filterRole?.id ||
        this.$route.query.show_deleted !=
          (this.filterShowTrashed !== undefined && this.filterShowTrashed !== '' ? this.filterShowTrashed.toString() : this.filterShowTrashed) ||
        this.$route.query.advertiser_id != this.selectedAdvertiser?.id ||
        this.$route.query.brand_id != this.selectedBrand?.id ||
        this.$route.query.search_type != this.searchType ||
        this.$route.query.brand_group_id != this.filters?.brand_group?.id ||
        this.$route.query.selected_type != this.filters?.type ||
        this.$route.query.brand_group_id_1 != this.filters?.brand_group_1?.id ||
        this.$route.query.brand_group_id_2 != this.filters?.brand_group_2?.id ||
        this.$route.query.date_from != this.filters?.date_from ||
        this.$route.query.date_to != this.filters?.date_to ||
        this.$route.query.discount_type != this.filterDiscountsType
      ) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            q: this.searchElements,
            sort: this.sortString,
            on_page: this.filterPage,
            per_page: this.selectedPerPage,
            agency_id: this.selectedAgency?.id,
            genre_id: this.selectedGenre?.id || this.filters?.genre?.id,
            year: this.filterDate?.id,
            channel_id: this.filterChannel?.id || this.filters?.channel?.id,
            saleshouse_id: this.filterSaleshouse?.id,
            role_id: this.filterRole?.id,
            show_deleted: this.filterShowTrashed,
            advertiser_id: this.selectedAdvertiser?.id,
            brand_id: this.selectedBrand?.id,
            search_type: this.searchType,
            selected_type: this.filters?.type,
            brand_group_id: this.filters?.brand_group?.id,
            brand_group_id_1: this.filters?.brand_group_1?.id,
            brand_group_id_2: this.filters?.brand_group_2?.id,
            date_from: this.filters?.date_from,
            date_to: this.filters?.date_to,
            discount_type: this.filterDiscountsType,
          },
        });
      }
    },

    async restoreFromQuery(restoreFrom) {
      if (this.q) this.searchElements = this.q;
      if (this.on_page) this.filterPage = +this.on_page;
      if (this.per_page && this.per_page != '10') this.selectedPerPage = this.per_page;
      if (this.sort_mode) {
        this.sortString = this.sort_mode;
        if (this.sortString && this.sortString.slice(-1) !== ',') this.sortString += ',';
      }

      if (restoreFrom === 'advertisers') {
        if (this.agency_id) this.selectedAgency = this.filterAgenciesList.find((el) => el.id === +this.agency_id);
        if (this.brand_id) this.selectedBrand = this.filterBrandsList.find((el) => el.id === +this.brand_id);
      } else if (restoreFrom === 'programs' && this.genre_id) {
        this.selectedGenre = this.filterGenresList.find((el) => el.id === +this.genre_id);
      } else if (restoreFrom === 'discounts') {
        if (this.selected_year) {
          this.filterDate = this.filterYearsList.find((el) => el.id === +this.selected_year);
          if (this.filterDate) {
            this.filterDateFrom = this.filterDate.id + '-01-01';
            this.filterDateTo = this.filterDate.id + '-12-31';
          }
        }
        if (this.discount_type) this.filterDiscountsType = +this.discount_type;
      } else if (restoreFrom === 'users') {
        if (this.search_type) this.searchType = this.search_type;
        if (this.agency_id) this.selectedAgency = this.filterAgenciesList.find((el) => el.id === +this.agency_id);
        if (this.channel_id) this.filterChannel = this.filterChannelsList.find((el) => el.id === +this.channel_id);
        if (this.saleshouse_id) this.filterSaleshouse = this.filterSaleshousesList.find((el) => el.id === +this.saleshouse_id);
        if (this.role_id) this.filterRole = this.filterRolesList.find((el) => el.id === +this.role_id);

        if (this.show_deleted) this.filterShowTrashed = this.show_deleted === 'true';
      } else if (restoreFrom === 'commercials') {
        if (this.search_type) this.searchType = this.search_type;
        if (this.advertiser_id) {
          this.selectedAdvertiser = this.filterAdvertisersList.find((el) => el.id === +this.advertiser_id);
          if (this.selectedAdvertiser && this.selectedAdvertiser.id) {
            await this.$store.dispatch('GET_BRANDS', {
              'filter[advertiser_id]': this.selectedAdvertiser.id,
              per_page: 1000,
            });
            this.filterBrandsList = this.brandsList;
            if (this.brand_id) this.selectedBrand = this.filterBrandsList.find((el) => el.id === +this.brand_id);
          }
        }
      } else if (restoreFrom === 'prohibitions') {
        if (this.selected_type) this.filters.type = this.selected_type;
        if (this.brand_group_id) this.filters.brand_group = this.filterGroupList.find((el) => el.id === +this.brand_group_id);
        if (this.genre_id) this.filters.genre = this.filterGenresList.find((el) => el.id === +this.genre_id);
      } else if (restoreFrom === 'coProhibitions') {
        if (this.brand_group_id_1) this.filters.brand_group_1 = this.filterGroupList1.find((el) => el.id === +this.brand_group_id_1);
        if (this.brand_group_id_2) this.filters.brand_group_2 = this.filterGroupList2.find((el) => el.id === +this.brand_group_id_2);
      } else if (restoreFrom === 'priceProjects') {
        if (this.date_from) this.filters.date_from = this.date_from;
        if (this.date_to) this.filters.date_to = this.date_to;
        if (this.selected_type) this.filters.type = this.selected_type;
        if (this.channel_id) this.filters.channel = this.filterChannelsList.find((el) => el.id === +this.channel_id);
      }
    },

    //Restore deleted entity
    async showModalRestoreEntity(row, requestName, string) {
      this.$bvModal
        .msgBoxConfirm(this.$i18n.t('table.confirmAction'), {
          title: string + ' ' + row?.name || '',
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-restore-modal',
        })
        .then((value) => {
          if (value) {
            const name = row?.name;
            this.$store.dispatch(requestName, {
              id: row?.id,
              handler: () => {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('alert.restored'),
                  text: name,
                });
                if (this.paramsData) this.paramsData();
              },
              handlerError: (errors) => {
                errorsHandler(errors, this.$notify);
              },
            });
          }
        })
        .catch((err) => {});
    },
  },
};

export default adminPanelQuery;
